import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import authReducer from "./slices/auth";
import authV2Reducer from "./slices/auth-v2";
import appReducer from "./slices/app";
import dashboardReducer from "./slices/dashboard";
import widgetsReducer from "./slices/widgets";
import transactionsReducer from "./slices/transactions";
import uploadReducer from "./slices/upload";
import duesReducer from "./slices/dues";
import payslipsReducer from "./slices/payslips";
import notesReducer from "./slices/notes";
import ancestryReducer from "./slices/ancestry";
import photosReducer from "./slices/photos";
import projectsReducer from "./slices/projects";
import financialCalculationsReducer from "./slices/financial-calculator";
import monthlyExpensesReducer from "./slices/monthly-expenses";
import expenseAccountsReducer from "./slices/expense-accounts";
import contactsReducer from "./slices/contacts";
import excelsReducer from "./slices/excels";
import vaultReducer from "./slices/vault";

const persistConfig = {
    storage,
    stateReconciler: autoMergeLevel2,
    timeout: null,
};

const persistedAuthReducer = persistReducer(
    { ...persistConfig, key: "auth" },
    authReducer
);
const persistedAppReducer = persistReducer(
    { ...persistConfig, key: "app" },
    appReducer
);

export const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        authV2: authV2Reducer,
        app: persistedAppReducer,
        dashboard: dashboardReducer,
        widgets: widgetsReducer,
        transactions: transactionsReducer,
        expenseAccounts: expenseAccountsReducer,
        monthlyExpenses: monthlyExpensesReducer,
        upload: uploadReducer,
        dues: duesReducer,
        payslips: payslipsReducer,
        notes: notesReducer,
        vault: vaultReducer,
        ancestry: ancestryReducer,
        photos: photosReducer,
        projects: projectsReducer,
        financialCalculations: financialCalculationsReducer,
        contacts: contactsReducer,
        excels: excelsReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

export { login, logOut, verify, verifyToken, forgotPassword, resetPassword } from "./slices/auth";
export { mockRecords, fetchEvents, fetchTodayEvents, setSettingsItem, fetchEventTypes, searchEvents, addEvent, removeEvent, setTheme, setFilters, gotoAndFocusEvent, resetFocusEvent, fetchCompanies, addCompany, removeCompany, fetchEventsByType, toggleNav } from "./slices/app";
export { setDt, setEditEventData, setView, fetchExpenses, addExpense, toggleDashboardNav, deleteExpense } from "./slices/dashboard";
export { setWidgetPdf } from "./slices/widgets";
export { fetchTransactions } from "./slices/transactions";
export { uploadFiles, getAllUploads, deleteUploadFile, amrUpload } from "./slices/upload";
export { fetchDues, addDue, removeDue } from "./slices/dues";
export { fetchNotes, addNote, removeNote } from "./slices/notes";
export { fetchPayslips, uploadPayslips, addPayslips, cancelAddPayslips } from "./slices/payslips";
export { fetchAncestry, updateProfileImage, addAncestry, removeAncestry } from "./slices/ancestry";
export { fetchPhotos, uploadPhotos, fetchPhotoDetails } from "./slices/photos";
export { fetchProjects } from "./slices/projects";
export { fetchCalculations, addCalculation, removeCalculation } from "./slices/financial-calculator";
export { fetchMonthlyExpenses, addMonthlyExpense, updateMonthlyExpenseStatus, deleteMonthlyExpense } from "./slices/monthly-expenses";
export { fetchExpenseAccounts, addExpenseAccount } from "./slices/expense-accounts";
export { fetchContacts, createUpdateContact, deleteContact } from "./slices/contacts";
export { fetchExcels, createUpdateExcel, deleteExcel } from "./slices/excels";
