import React, { useState } from 'react';
import "./passcode-lock.scss";
import { useDispatch } from 'react-redux';
import { passcodeLogin } from '../../store/slices/auth-v2';
import { FormField } from '../../ui-components';
import { useCrypto } from '../../utils';

const ComponentPasscodeLock = ({ children }) => {
    const reduxDispatch = useDispatch();
    const { encrypt } = useCrypto();

    const [passcode, setPasscode] = useState('');
    const [validPasscode, setValidPasscode] = useState(false);
    const [validatingPasscode, setValidatingPasscode] = useState(false);

    const validatePasscode = e => {
        e.preventDefault();
        setValidatingPasscode(true);
        reduxDispatch(passcodeLogin(encrypt(passcode))).then(data => {
            setPasscode('');
            setValidatingPasscode(false);
            setValidPasscode(data?.payload?.data?.status >= 200 && data?.payload?.data?.status < 300);
        });
    }

    return validPasscode ? 
        children :
        <div className='passcode-wrap'>
            <form className='passcode-container' onSubmit={validatePasscode}>
                <div className='passcode-account'>
                    <div className='passcode-account-name'>vijeedesigns@gmail.com</div>
                    <div className='passcode-account-change-name'>Change</div>
                </div>
                <div className='passcode-fields'>
                    <FormField type='password' value={passcode} onChange={setPasscode} className='passcode-field' placeholder='Enter passcode' />
                    <button className='btn btn-primary btn-cell passcode-submit' type='submit' disabled={validatingPasscode}><i className="icon icon-arrow-right" /></button>
                </div>
            </form>
        </div>
    ;
};

export default ComponentPasscodeLock;
