import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchHandler } from "../fetchHandler";

export const passcodeLogin = createAsyncThunk("passcodeLogin", async (passcode) => {
    return await fetchHandler({
        url: "/auth/passcode-login",
        method: "POST",
        secure: false,
        body: { passcode }
    });
});

export const authV2Slice = createSlice({
    name: "authV2",
    initialState: {
        passcodeLoginLoading: false,
        passcodeData: null
    },
    reducers: {
        logOut: (state) => {
            state.userDetails = null;
            state.jwt = null;
        },
    },
    extraReducers: {
        [passcodeLogin.pending]: (state) => {
            state.passcodeLoginLoading = true;
        },
        [passcodeLogin.fulfilled]: (state, action) => {
            if(action?.payload?.data?.passcode) state.passcodeData = action?.payload?.data?.passcode;
            state.passcodeLoginLoading = false;
        },
        [passcodeLogin.rejected]: (state) => {
            state.passcodeLoginLoading = false;
        }
    },
});

export default authV2Slice.reducer;
