import React, { useEffect, useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { verifyToken } from "./store";
import { VERIFY } from "./constants";
import {
    PageLogin,
    PageForgotPassword,
    PageResetPassword,
    PageDashboard,
    PageTransactions,
    PageTransactionsHistory,
    PageSettings,
    PageEvents,
    PageDues,
    PagePayslips,
    PageNotes,
    PageAncestry,
    PageXLSXExport,
    PagePhotos,
    PageFinancialCalculator,
    PageRnD,
    PageTodo,
    PageContacts,
    PageSheets,
    PageVault
} from "./pages";
import { AlertProvider, Loading } from "./ui-components";

function App() {
    const reduxDispatch = useDispatch();
    const { theme } = useSelector((state) => state?.app);
    const { jwt } = useSelector((state) => state?.auth);

    const [authVerified, setAuthVerified] = useState(VERIFY.VERIFYING);

    useEffect(() => {
        setTimeout(() => {
            reduxDispatch(verifyToken()).then((data) => {
                const isSuccess = data?.payload?.status === 200;
                setAuthVerified(
                    isSuccess ? VERIFY.VERIFIED : VERIFY.NOT_VERIFIED
                );
            });
        }, 10);
    }, [jwt, reduxDispatch]);

    useEffect(() => {
        document.body.classList.remove("app-light", "app-dark");
        document.body.classList.add(theme);
    }, [theme]);

    const renderRoute = () => {
        switch (authVerified) {
            case VERIFY.NOT_VERIFIED:
                return (
                    <Routes>
                        <Route path="*" element={<Suspense fallback={<Loading />}><PageLogin /></Suspense>} />
                        <Route path="/forgot-password" element={<Suspense fallback={<Loading />}><PageForgotPassword /></Suspense>} />
                        <Route path="/reset-password/:resetId" element={<Suspense fallback={<Loading />}><PageResetPassword /></Suspense>} />
                    </Routes>
                );
            case VERIFY.VERIFIED:
                return (
                    <>
                        <Routes>
                            <Route path="/" element={<Suspense fallback={<Loading />}><PageDashboard /></Suspense>} />
                            <Route path="/transactions" element={<Suspense fallback={<Loading />}><PageTransactions /></Suspense>} />
                            <Route path="/transactions-history" element={<Suspense fallback={<Loading />}><PageTransactionsHistory /></Suspense>} />
                            <Route path="/settings" element={<Suspense fallback={<Loading />}><PageSettings /></Suspense>} />
                            <Route path="/events" element={<Suspense fallback={<Loading />}><PageEvents /></Suspense>} />
                            <Route path="/dues" element={<Suspense fallback={<Loading />}><PageDues /></Suspense>} />
                            <Route path="/payslips" element={<Suspense fallback={<Loading />}><PagePayslips /></Suspense>} />
                            <Route path="/notes" element={<Suspense fallback={<Loading />}><PageNotes /></Suspense>} />
                            <Route path="/ancestry" element={<Suspense fallback={<Loading />}><PageAncestry /></Suspense>} />
                            <Route path="/xlsx-export" element={<Suspense fallback={<Loading />}><PageXLSXExport /></Suspense>} />
                            <Route path="/photos" element={<Suspense fallback={<Loading />}><PagePhotos /></Suspense>} />
                            <Route path="/photos/view/:viewid" element={<Suspense fallback={<Loading />}><PagePhotos /></Suspense>} />
                            <Route path="/financial-alculator" element={<Suspense fallback={<Loading />}><PageFinancialCalculator /></Suspense>} />
                            <Route path="/rnd/*" element={<Suspense fallback={<Loading />}><PageRnD /></Suspense>} />
                            <Route path="/todo" element={<Suspense fallback={<Loading />}><PageTodo /></Suspense>} />
                            <Route path="/contacts" element={<Suspense fallback={<Loading />}><PageContacts /></Suspense>} />
                            <Route path="/sheets" element={<Suspense fallback={<Loading />}><PageSheets /></Suspense>} />
                            <Route path="/vault" element={<Suspense fallback={<Loading />}><PageVault /></Suspense>} />
                        </Routes>
                    </>
                );
            default:
                return <Loading />;
        }
    };

    return (
        <AlertProvider>
            <div className={`memo-vijee-in`}>{renderRoute()}</div>
        </AlertProvider>
    );
}

export default App;
