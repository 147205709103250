import LogoWithBg from "./icon.png";
import LogoFull from "./app-title.png";

import LogoHDFC from "./hdfc-logo.png";
import LogoICICI from "./icici-logo.png";
import LogoSBI from "./sbi-logo.png";

import IconCalendar from "./logo-calendar.png";
import IconTransaction from "./logo-transactions.png";
import IconEvents from "./logo-events.png";
import IconDues from "./logo-dues.png";
import IconPayslips from "./logo-payslips.png";
import IconNotes from "./logo-notes.png";
import IconSettings from "./logo-settings.png";
import IconAncestry from "./logo-ancestry.png";
import IconPhotos from "./logo-photos.png";
import IconFinancialCalculations from "./logo-financial-calculations.png";
import IconTodo from "./logo-todo.png";
import IconContacts from "./logo-contacts.png";
import IconVault from "./logo-vault.png";

export {
    LogoWithBg,
    LogoFull,
    IconCalendar,
    IconTransaction,
    IconEvents,
    IconDues,
    IconPayslips,
    IconNotes,
    IconSettings,
    IconAncestry,
    IconPhotos,
    IconFinancialCalculations,
    IconTodo,
    IconContacts,
    IconVault,
    LogoHDFC,
    LogoICICI,
    LogoSBI,
};

const AssetsBundle = {
    LogoWithBg,
    LogoFull,
    IconCalendar,
    IconTransaction,
    IconEvents,
    IconDues,
    IconPayslips,
    IconNotes,
    IconSettings,
    IconAncestry,
    IconPhotos,
    IconFinancialCalculations,
    IconTodo,
    IconContacts,
    LogoHDFC,
    LogoICICI,
    LogoSBI,
};

export default AssetsBundle;
