import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import {
    IconCalendar,
    IconTransaction,
    IconEvents,
    IconDues,
    IconPayslips,
    IconNotes,
    IconSettings,
    IconAncestry,
    IconPhotos,
    IconFinancialCalculations,
    IconTodo,
    IconContacts,
    IconVault
} from "../../assets/images";
import { prePad } from "../../helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut, toggleNav } from "../../store";
import { getEventPath } from "../../utils";

import "./index.scss";

const mainNavRootID = "memo-app-nav-root";
const mainNavRoot = document.createElement("div");
mainNavRoot.id = mainNavRootID;
const getMainNavRootElement = () => document.getElementById(mainNavRootID);
if (!getMainNavRootElement()) document.body.prepend(mainNavRoot);

const PortalMainNav = () => {
    const mainNavRootElement = getMainNavRootElement();
    const { navOpen } = useSelector((state) => state?.app);
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();
    const [confirmLogout, setConfirmLogout] = useState(false);

    const logoutFn = () => {
        reduxDispatch(logOut());
    };

    const handleConfirmClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setConfirmLogout(true);
    };

    const renderMainNav = () => {
        return (
            <div className={`memo-app-nav-container ${navOpen ? "open" : ""}`}>
                <ul className="mb-2">
                    <li
                        className="memo-app-nav-item menu-calendar"
                        onClick={() => navigate("/")}
                    >
                        <img src={IconCalendar} alt="Calendar" />
                        <div className="calendar-date">
                            {prePad(new Date().getDate())}
                        </div>
                        <span>Calendar</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-transaction"
                        onClick={() => navigate("/transactions")}
                    >
                        <img src={IconTransaction} alt="Transaction" />
                        <span>Transaction</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-photos"
                        onClick={() => navigate("/photos")}
                    >
                        <img src={IconPhotos} alt="Photos" />
                        <span>Photos</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-events"
                        onClick={() => navigate("/events")}
                    >
                        <img src={IconEvents} alt="Events" />
                        <span>Events</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-dues"
                        onClick={() => navigate("/dues")}
                    >
                        <img src={IconDues} alt="Dues" />
                        <span>Dues</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-payslips"
                        onClick={() => navigate("/payslips")}
                    >
                        <img src={IconPayslips} alt="Payslips" />
                        <span>Payslips</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-notes"
                        onClick={() => navigate("/notes")}
                    >
                        <img src={IconNotes} alt="Notes" />
                        <span>Notes</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-ancestry"
                        onClick={() => navigate("/ancestry")}
                    >
                        <img src={IconAncestry} alt="Ancestry" />
                        <span>Ancestry</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-ancestry"
                        onClick={() => navigate("/financial-alculator")}
                    >
                        <img
                            src={IconFinancialCalculations}
                            alt="Financial Calculations"
                        />
                        <span>Financial Calculations</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-todo"
                        onClick={() => navigate("/todo")}
                    >
                        <img src={IconTodo} alt="Todo" />
                        <span>Todo</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-contacts"
                        onClick={() => navigate("/contacts")}
                    >
                        <img src={IconContacts} alt="Contacts" />
                        <span>Contacts</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-vault"
                        onClick={() => navigate("/vault")}
                    >
                        <img src={IconVault} alt="Vault" />
                        <span>Vault</span>
                    </li>
                    <li
                        className="memo-app-nav-item menu-settings"
                        onClick={() => navigate("/settings")}
                    >
                        <img src={IconSettings} alt="Settings" />
                        <span>Settings</span>
                    </li>
                </ul>
                <div className="divider" />
                <ul className="mt-2">
                    {confirmLogout ? (
                        <li className="memo-app-nav-item menu-logout-confirm">
                            <div className="are-you-sure">
                                Are you sure want to logout ?
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={logoutFn}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setConfirmLogout(false)}
                            >
                                No
                            </button>
                        </li>
                    ) : (
                        <li
                            className="memo-app-nav-item menu-logout"
                            onClick={handleConfirmClick}
                        >
                            <i className="icon icon-logout" />
                            <span>Logout</span>
                        </li>
                    )}
                </ul>
            </div>
        );
    };

    return ReactDOM.createPortal(renderMainNav(), mainNavRootElement);
};

const ComponentMainNav = ({ className = "", label = "" }) => {
    const { navOpen } = useSelector((state) => state?.app);
    const reduxDispatch = useDispatch();

    const ref = useRef();

    useEffect(() => {
        const listener = (e) => {
            const path = getEventPath(e);
            const innerClick = path.includes(ref?.current);
            if (!innerClick && navOpen) {
                reduxDispatch(toggleNav());
            }
        };
        window.addEventListener("click", listener);
        return () => {
            window.removeEventListener("click", listener);
        };
    }, [reduxDispatch, navOpen]);

    return (
        <nav className={`memo-app-nav-wrap ${navOpen ? "open" : ""}`} ref={ref}>
            <div
                className={`page-header-logo ${className}`}
                onClick={() => reduxDispatch(toggleNav())}
            >
                {label}
            </div>
            <PortalMainNav />
        </nav>
    );
};

export default ComponentMainNav;
