import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchHandler } from '../fetchHandler';

export const fetchVault = createAsyncThunk('fetchVault', async () => {
    const response = await fetchHandler({
        url: `/vault/get-list`,
        method: 'GET'
    });
    return response?.data;
});

export const addVaultItem = createAsyncThunk('addVaultItem', async (body) => {
    const response = await fetchHandler({
        url: `/vault/add-edit`,
        method: 'POST',
        body
    });
    return response?.data;
});

export const removeVaultItem = createAsyncThunk('removeVaultItem', async (body) => {
    const response = await fetchHandler({
        url: `/vault/remove`,
        method: 'DELETE',
        body
    });
    return response?.data;
});

export const vaultSlice = createSlice({
    name: 'vault',
    initialState: {
        vault: [],
        vaultLoading: false
    },
    extraReducers: {
        [fetchVault.pending]: (state) => {
            state.vaultLoading = true;
        },
        [fetchVault.fulfilled]: (state, action) => {
            state.vaultLoading = false;
            state.vault = action?.payload?.result;
        },
        [fetchVault.rejected]: (state) => {
            state.vaultLoading = false;
        }
    }
});

export default vaultSlice.reducer;
